import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import FormLanding from "./../screens/webinar-replay/2021-12-08-harnett/form-landing"
import BodyLanding from "./../screens/webinar-replay/2021-12-08-harnett/body-landing"

const HarnettReplay = () => {
  return (
    <React.Fragment>
      <FormLanding />
      <BodyLanding pageName="display" localStorageID="2021-12-08-harnett-webinar-replay" />
    </React.Fragment>
  )
}

export default HarnettReplay

export const Head = () => (
  <Seo
        title="Webinar Replay: Improving Antimicrobial Stewardship in STIs"
        description="This webinar covers the 2021 CDC STI guidelines, explores challenges with traditional STI testing and the impact on antibiotic stewardship."
        image="/meta/webinar-harnett-2021-12-08.jpg"
        recaptcha
  />
)
